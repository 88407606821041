<template >
  <router-view />
</template>
<script>
export default {
  name: 'intergratingManage',
  //处理三级路由
  created() {
    const route = this.$route.matched.find(item => item.name === 'intergratingManage');
    this.$store.dispatch('addCachedView', route);
  }
}
</script>
